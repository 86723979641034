// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/ui/molecules/Avatar.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/ui/molecules/Avatar.tsx");
  import.meta.hot.lastModified = "1726176605635.3848";
}
// REMIX HMR END

import { cva } from 'class-variance-authority';
import * as React from 'react';
import { hash } from '@/utils';
import { Avatar as AvatarAtom } from '~/ui/atoms/Avatar';
import { getProxyUrl } from '~/routes/api.proxy';
import { cn } from '~/utils/cn';
export const avatarVariants = cva('flex justify-center items-center flex-shrink-0 relative overflow-hidden select-none', {
  variants: {
    size: {
      xs: 'h-4 w-4 text-[8px] rounded-[2px]',
      sm: 'h-6 w-6 text-[12px] rounded',
      md: 'h-7 w-7 text-[14px] rounded',
      base: 'h-8 w-8 text-[16px] rounded'
    }
  },
  defaultVariants: {
    size: 'base'
  }
});

/**
 * Deterministically maps a name to a color.
 *
 * @param name
 * @returns
 */
function nameToColor(name, colors) {
  const nameHash = hash(name);
  const index = nameHash % colors.length;
  return colors[index];
}

/**
 * Initials rules:
 * Vishal M = VM
 * Chao Li = CL
 * chao = CH
 * Tywen = TY
 * Tywen Super Kelly = TK
 *
 * @param name
 * @returns Capitalized initials
 */
function getUserInitials(name) {
  if (!name) return '';
  const parts = name.trim().split(' '); // Split name into parts based on whitespace
  // Handle empty names, which should not happen
  if (parts.length === 0) {
    return '';
  }
  // Handle case for single word names differently to ensure they get first two letters
  if (parts.length === 1) {
    return parts[0].substring(0, 2).toUpperCase();
  }
  // For names with two or more parts, initialize the first and last parts
  const firstInitial = parts[0].charAt(0);
  const lastInitial = parts[parts.length - 1].charAt(0);
  return (firstInitial + lastInitial).toUpperCase();
}
function getAccountInitials(name) {
  if (!name) return '';
  return name[0].toUpperCase();
}

// Base props without user and account

// User only props

// Account only props

// Combined AvatarProps type with discriminated union

const Avatar = React.forwardRef(_c = ({
  user,
  account,
  className,
  size,
  ...props
}, ref) => {
  const avatar = user?.avatar ?? account?.avatar;
  const name = user?.name ?? account?.name;
  return <AvatarAtom ref={ref} className={className} {...props}>
      {/* Using a proxy to allow us to fetch images from a third party,
       while still keeping our CORs/COEP/COOP headers more strict */}
      <AvatarAtom.Image src={getProxyUrl(avatar)} alt={name} className={avatarVariants({
      size
    })} />
      <AvatarAtom.Fallback className={cn(avatarVariants({
      size
    }), name ? nameToColor(name, ['bg-pink-500', 'bg-purple-500', 'bg-sky-500', 'bg-teal-500', 'bg-indigo-500', 'bg-blue-500']) : 'bg-gray-500')}>
        {account ? getAccountInitials(name) : getUserInitials(name)}
      </AvatarAtom.Fallback>
    </AvatarAtom>;
});
_c2 = Avatar;
Avatar.displayName = AvatarAtom.displayName;
export { Avatar };
var _c, _c2;
$RefreshReg$(_c, "Avatar$React.forwardRef");
$RefreshReg$(_c2, "Avatar");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;